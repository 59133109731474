<template>
  <div
    style="
      background-color: #171726;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
  >
    <div
      class="top_section"
      style="background-image: url('img/myIncome/top_bg.png')"
    >
      <div class="record_title">
        <img
          class="record_back"
          src="img/backw.png"
          alt=""
          @click="closePage"
        />
        <span>我的收益</span>
        <span class="rank_item_" @click="toInfoManage">管理</span>
      </div>
      <div class="nickname">你好，{{nickname}}</div>
      <div class="my_income">{{ money }}</div>
      <div class="my_income_tip">剩余收益(元)</div>
      <div class="my_income_bottom_tip">您可使用这部分收益进行兑换提现</div>
    </div>
    <div
      class="bottom_section"
      style="background-image: url('img/myIncome/bottom_bg.png')"
    >
      <div class="icon_box">
        <div
          class="icon"
          style="background-image: url('img/myIncome/record.png')"
          @click="toBillManage"
        ></div>
        <div
          class="icon"
          :style="
            'background-image: url(img/myIncome/wallet.png)' +
            (moduleData.withdrawal_open == 1 ? '' : 'visibility: hidden')
          "
          @click="toWithdraw"
        ></div>
        <div
          class="icon"
          :style="
            'background-image: url(img/myIncome/exchange.png);' +
            (moduleData.exchange_open == 1 ? '' : 'visibility: hidden')
          "
          @click="toExchange"
        ></div>
      </div>
      <div class="icon_box">
        <div
          class="icon"
          style="background-image: url('img/myIncome/recharge.png')"
          @click="toRecharge"
        ></div>
        <!-- <div
          class="icon"
          :style="
            'background-image: url(img/myIncome/redbag.png);' +
            (moduleData.send_open == 1 ? '' : 'visibility: hidden')
          "
        ></div> -->
        <!-- <div
          class="icon"
          :style="
            'background-image: url(img/myIncome/redbag.png);visibility: hidden'"></div> -->
      </div>
    </div>
    <!-- <div class="mask" v-if="showMask">
      <div class="modal_content">
        <div class="modal_title">提示</div>
        <div class="modal_tip">
          您还没有获取APP授权，需点击下方“获取授权”按钮去APP获取授权。
        </div>
        <div class="modal_btn">
          <div class="modal_btn_item cancel">取消</div>
          <div class="modal_btn_item enter" @click="getToken">获取授权</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import "@/assets/css/relationNet.css";
// import "@/utils/jweixin.js";
import { userWallet, moduleDisplay ,getOpenId} from "@/api/wallet";
import BackNav from "@/components/backNav.vue";
export default {
  name: "myIncome",
  components: {
    BackNav,
  },
  data() {
    return {
      access_token: "",
      money: "0.000",
      moduleData: {},
      nickname:'',
      openId:''
    };
  },
  created() {
    let token = localStorage.getItem("access_token");
    if (token) {
      this.access_token = token;
      this.moduleDisplay();
      this.getUserWallet();
    } else {
      this.$toast.fail("登录过期，2S后将返回登录页");
      let that = this
      setTimeout(function(){
        that.$router.push({
        path: "/phoneLogin",
      });
      },2000)
    }
  },
  mounted() {},
  methods: {
    closePage() {
      // console.log("执行了");
      // let params = "调用";
      // this.$bridge.callhandler("closePage", params, (data) => {
      //   // 处理返回数据
      // });
    },
    // pasteText() {
    //   navigator.clipboard.readText().then((access_token) => {
    //     alert('access_token'+'-'+access_token)
    //     if (access_token!=''&&access_token!=undefined) {
    //       this.access_token = access_token;
    //       alert('this_access_token'+'-'+this.access_token)
    //       localStorage.setItem("access_token", this.access_token);
    //
    //     } else {
    //       this.showMask = true;
    //     }
    //   });
    // },
    toBillManage() {
      this.$router.push({ path: "/billManage", query: {} });
    },
    toWithdraw() {
      this.$router.push({ path: "/withDrawActive", query: {} });
    },
    toExchange() {
      this.$router.push({ path: "/exchangeActive", query: {} });
    },
    toInfoManage() {
      this.$router.push({ path: "/infoManage", query: {} });
    },
    toRecharge(){
        this.$router.push({ path: "/rechargeIndex",query: {} });
    },
    getUserWallet() {
      userWallet({})
        .then(
          (response) => {
            this.money = response.data.money;
            this.nickname = response.data.nickname;
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    moduleDisplay() {
      moduleDisplay({}).then((res) => {
        this.moduleData = res.data;
      });
    },
    // getToken() {
    //   window.location.href = "player://dbjy.com";
    //   this.showMask = false;
    // },
  },
};
</script>

<style scoped>
.top_section {
  width: 100%;
  height: 880px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bottom_section {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -30px;
}

.my_income {
  font-size: 88px;
  text-align: center;
  color: #ffffff;
  margin-top: 200px;
}

.my_income_tip {
  font-size: 30px;
  color: #ffffff;
  opacity: 0.5;
  text-align: center;
}

.my_income_bottom_tip {
  font-size: 24px;
  color: #ffffff;
  opacity: 0.5;
  text-align: center;
  margin-top: 290px;
}

.icon {
  width: 127px;
  height: 166px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.icon_box {
  display: flex;
  justify-content: space-around;
  margin-top: 80px;
}
.mask {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_content {
  width: 80%;
  height: 20%;
  background-color: #ffffff;
  padding: 0 20px 0 20px;
  position: relative;
}
.modal_title {
  color: #111111;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
.modal_tip {
  font-size: 24px;
  color: #111111;
  margin-top: 30px;
}
.modal_btn {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  border-top: 1px solid #efefef;
}
.modal_btn_item {
  flex: 1;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
}
.cancel {
  border-right: 1px solid #efefef;
}
.enter {
  color: #409eff;
}
.nickname{
  color: #ffffff;
  text-align: left;
  text-indent: 40px;
  margin-top: 20px;
}
</style>
