import request from '@/utils/request'
export function userWallet(data){
    return request({
      url:'/web/users/userWallet',
      method:'get',
      data
    })
  }

  export function moduleDisplay(data){
    return request({
      url:'/web/users/moduleDisplay',
      method:'get',
      data
    })
  }

  export function walletIndex(data){
    return request({
      url:'/web/wallet/walletIndex',
      method:'post',
      data
    })
  }

  export function applyWithdraw(data){
    return request({
      url:'/web/wallet/applyWithdraw',
      method:'post',
      data
    })
  }
  export function applyExchange(data){
    return request({
      url:'/web/wallet/applyExchange',
      method:'post',
      data
    })
  }
  export function getSmsCode(data){
    return request({
      url:'/web/getSmsCode',
      method:'post',
      data
    })
  }
  export function getSmsCodeLogin(data){
    return request({
      url:'/public/getSmsCode',
      method:'post',
      data
    })
  }
  export function bindPhone(data){
    return request({
      url:'/web/setting/bindPhone',
      method:'post',
      data
    })
  }
  export function phoneLogin(data){
    return request({
      url:'/public/officialAccount',
      method:'post',
      data
    })
  }
  export function bindAlipay(data){
    return request({
      url:'/web/wallet/editAli',
      method:'post',
      data
    })
  }
  export function bindBank(data){
    return request({
      url:'/web/wallet/editBank',
      method:'post',
      data
    })
  }
  export function bindUserInfo(data){
    return request({
      url:'/web/wallet/bindPayee',
      method:'post',
      data
    })
  }
  export function setSecondPassword(data){
    return request({
      url:'/web/wallet/setSecondPassword',
      method:'post',
      data
    })
  }
  export function editSecondPassword(data){
    return request({
      url:'/web/wallet/editSecondPassword',
      method:'post',
      data
    })
  }
  export function turnSecondPassword(data){
    return request({
      url:'/web/wallet/turnSecondPassword',
      method:'post',
      data
    })
  }
  export function getOpenId(data){
    return request({
      url:'/direct/recharge/wechatLogin',
      method:'post',
      data
    })
  }
    export function wxPay(data){
    return request({
      url:'/direct/recharge/wxJsPay',
      method:'post',
      data
    })
  }
  // export function sdPay(data){
  //   return request({
  //     url:'/api/recharge/sandJsApiPay',
  //     method:'post',
  //     data
  //   })
  // }
  export function rechargeList(data){
    return request({
      url:'/direct/recharge/rechargeList',
      method:'get',
      data
    })
  }
  export function payUserInfo(data){
    return request({
      url:'/direct/recharge/payUserInfo',
      method:'get',
      data
    })
  }